<template>
	<v-layout column fill-height>
		<v-layout v-if="pageLoading" align-center fill-height justify-center>
			<v-progress-circular color="primary" indeterminate size="50" width="6" />
		</v-layout>
		<v-layout v-else-if="!hasVendors" fill-height row>
			<w-flex offset-xs1 xs4 mr-2>
				<w-layout align-center column fill-height justify-center>
					<w-flex v-t="'holding.team-manager.themes.no_themes_available_title'" display-1 mb-4 shrink />
					<w-flex v-t="'holding.team-manager.themes.no_themes_available_text'" mb-2 shrink subheading />
					<w-btn @click="goToPage(vendorListPage)">{{ $t('actions.click_here') }}</w-btn>
				</w-layout>
			</w-flex>
			<w-flex xs6 ml-2>
				<w-layout align-center fill-height>
					<NoWorkspaceAvailableImage height="80%" />
				</w-layout>
			</w-flex>
		</v-layout>
		<template v-else>
			<v-flex shrink>
				<v-toolbar dense>
					<w-search-input v-model="filters.search" :placeholder="$t('holding.team-manager.themes.search')" />
					<w-spacer />
				</v-toolbar>
			</v-flex>
			<v-flex px-4 py-1 scroll-y>
				<v-layout column>
					<v-flex my-1 shrink>
						<w-text-info :text="$t('holding.team-manager.themes.text_info')" />
					</v-flex>
					<ThemesTable :search="filters.search" :value="selectedUserId" />
				</v-layout>
			</v-flex>
		</template>
	</v-layout>
</template>

<script>
import HoldingUserModuleGuard from '@/mixins/ModulesGuards/Holding/HoldingUserModuleGuard'

export default {
	name: 'HoldingUserSettingsThemes',
	components: {
		NoWorkspaceAvailableImage: () => ({
			component: import('@/components/Holding/NoWorkspaceAvailableImage')
		}),
		ThemesTable: () => ({
			component: import('@/components/Holding/HoldingUserSettings/ThemesTable')
		})
	},
	mixins: [HoldingUserModuleGuard],
	inject: ['holdingContext'],
	props: {
		value: {
			required: true,
			type: Number
		}
	},
	data: function () {
		return {
			filters: {
				search: null
			},
			hasVendors: false,
			headers: [
				{
					text: this.$t('holding.team-manager.themes.labels.title'),
					value: 'title'
				},
				{
					sortable: false,
					text: this.$t('holding.team-manager.themes.labels.shared')
				},
				{
					sortable: false,
					text: this.$t('holding.team-manager.themes.labels.permissions')
				},
				{
					sortable: false,
					text: this.$t('holding.team-manager.themes.labels.customer')
				}
			],
			loading: false,
			pageLoading: false,
			pagination: {
				descending: false,
				sortBy: 'title'
			},
			themes: [],
			vendorListPage: { route: 'holding-team-user-vendors' }
		}
	},
	computed: {
		holdingId: function () {
			return this.holdingContext?.holding_id
		},
		selectedUserId: function () {
			return this.value
		}
	},
	watch: {
		holdingId: {
			handler: function (holdingId) {
				if (holdingId) {
					this.loadPage()
				}
			}
		}
	},
	mounted: function () {
		if (this.holdingId) {
			this.loadPage()
		}
	},
	methods: {
		goToPage: function (page) {
			const currentParams = this.$route.params
			this.appService.goTo({
				name: page.route,
				params: currentParams
			})
		},
		listVendors: function () {
			this.pageLoading = true
			return this.service
				.listVendors(this.holdingId, this.selectedUserId)
				.then(vendors => {
					this.hasVendors = vendors.length > 0
				})
				.finally(() => {
					this.pageLoading = false
				})
		},
		loadPage: function () {
			return this.listVendors()
		}
	}
}
</script>